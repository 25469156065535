<template>
  <div class="app-container">
    <CrudTable entity="PostcodeRestaurant" :columns="columns" :allow-create="false" :allow-delete="false" />
  </div>
</template>

<script>
import CrudTable from '@/components/crud/CrudTable';
export default {
  components: {
    CrudTable
  },
  data() {
    return {
      columns: [
        {
          field: 'postcode',
          label: 'common.postalCode'
        },
        'areaName'
      ]
    };
  }
};
</script>

<style scoped></style>
